import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import * as VueGoogleMaps from "vue2-google-maps";

import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_FB_MAP_KEY,
    libraries: "places",
  },
  installComponents: false,
});

Vue.component("google-map", VueGoogleMaps.Map);
Vue.component("google-marker", VueGoogleMaps.Marker);
Vue.component("google-info-window", VueGoogleMaps.InfoWindow);

/* eslint-disable  */
Vue.directive("click-outside", {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind: function (el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

Vue.config.productionTip = false;

Vue.use(VueAxios, axios);

Vue.mixin({
  methods: {
    log: (item) => console.log(item),
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
