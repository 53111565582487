<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body" v-click-outside="handleClick">
            <slot name="body">
              <google-map
                id="map"
                ref="gmap"
                :center="{ lng: -100, lat: 38.7 }"
                :zoom="4.9"
                style="flex: 1"
                :options="options"
              >
                <google-marker
                  v-for="marker of markers"
                  :key="marker.id"
                  :clickable="true"
                  :draggable="false"
                  :position="marker.position"
                  :icon="getMarkerOptions(marker.icon)"
                >
                </google-marker>
              </google-map>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
/* eslint-disable */
import { gmapApi } from "vue2-google-maps";
export default {
  name: "MyMaps",
  props: {
    markers: Array
  },
  data() {
    return {
      showByIndex: null,
      infoOptions: {
        content: "",
        disableAutoPan: true
      },
      options: {
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: true,
        disableDefaultUi: false,
        maxZoom: 4.9,
        zoom: 3.3,
        minZoom: 3.3,
        zoomControl: false,
        scrollwheel: false,
        mapTypeId: "hybrid",
        restriction: {
          latLngBounds: {
            north: 49.1,
            south: 25.9,
            east: -67,
            west: -128
          }
        }
      }
    };
  },
  computed: {
    google: gmapApi
  },
  methods: {
    handleClick(e) {
      this.$emit("close");
    },

    getMarkerOptions(iconLink) {
      if (!iconLink) return null;
      return {
        url: iconLink,
        size: { width: 25, height: 30, f: "px", b: "px" },
        scaledSize: { width: 25, height: 30, f: "px", b: "px" }
      };
    }
  }
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  display: flex;
  flex-direction: column;
  width: 80vw;
  min-height: 73vh;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  height: 100%;
  display: flex;
  flex: 1;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
